select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
  position: relative;
}

.icon-walkin {
  background-image: url(./static/paper-plane-regular.svg);
  width: 1.4rem;
  height: 1.4rem;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: relative;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
}

.icon-sendin {
  background-image: url(./static/envelope-regular.svg);
  width: 1.4rem;
  height: 1.4rem;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  position: relative;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
}

.react-datepicker__input-container input::placeholder{
  color: #000;
}

.modal-overlay{
  position: fixed;
  background-color: rgba(100,100,100,0.5);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.modal-content{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.not-found{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.react-datepicker__year-dropdown-container{
  margin-left: 24px !important;
}

.react-datepicker__header__dropdown{
  padding-top: 8px !important;
}
